<template>
  <div class="">
    <!-- <van-nav-bar
      title="登录"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
    /> -->
    <div class="swipe">
      <van-swipe>
        <van-swipe-item
          ><img
            :src="require('@/assets/images/login.png')"
            alt=""
            style="width: 100%; height: 100%"
        /></van-swipe-item>
      </van-swipe>
    </div>
    <div class="clean"></div>
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <!-- <van-field
          is-link
          readonly
          clickable
          name="picker"
          :value="loginForm.tenantName"
          v-model="loginForm.tenantName"
          @click="showPicker = true"
          label="机构"
          placeholder="请选择机构"
        /> -->
        <!-- <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup> -->
        <van-field
          required
          v-model="loginForm.username"
          name="username"
          label="用户名"
          placeholder="请填写用户名"
          :rules="[{ required: true, message: '用户名不能为空' }]"
        />

        <van-field
          required
          v-model="loginForm.password"
          type="password"
          name="password"
          label="密码"
          placeholder="请填写密码"
          :rules="[{ required: true, message: '密码不能为空' }]"
        />

        <van-field
          required
          v-model="loginForm.code"
          name="code"
          label="验证码"
          placeholder="请填写验证码"
          :rules="[{ required: true, message: '验证码不能为空' }]"
        >
        </van-field>
        <div class="login-code">
          <img
            :src="loginForm.image"
            class="login-code-img"
            @click="refreshCode"
          />
        </div>
      </van-cell-group>
      <div style="margin: 16px">
        <van-button
          round
          block
          :loading="isloading"
          :disabled="isloading"
          loading-text="正在登录ing..."
          type="info"
          native-type="submit"
        >
          登录
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { getCaptcha, getTenantIdListAPI } from "@/api/index";
import { Toast } from "vant";
import dateUtil from "@/api/dateUtil.js";
import { setStore, getStore, removeStore } from "@/utils/localStorage.js";
import {
  setToken,
  setRefreshToken,
  removeToken,
  removeRefreshToken,
} from "@/utils/token";
import website from "@/config/website";
export default {
  data() {
    return {
      isloading: false,
      columns: [],
      showPicker: false,
      loginForm: {
        tenantId: "", // 部门ID
        tenantName: "",
        username: "",
        password: "",
        type: "account", // 账号类型
        code: "", // 验证码的值
        key: "", // 验证码的索引
        image:
          "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7", // 预加载白色背景
      },
    };
  },

  created() {
    removeStore({ name: "token" });
    removeStore({name:"deptCode"})
    localStorage.removeItem("data");
    localStorage.removeItem("itemData")
    this.refreshCode();
    // this.getTenantIdList();
  },
  methods: {
    async onSubmit(value) {
      this.isloading = true;
      this.loginForm.grant_type = "captcha";
      this.loginForm.scope = "all";
      this.loginForm.tenantId = localStorage.getItem("tenantId") || 100001;
      this.$store
        .dispatch("LoginByUsername", this.loginForm)
        .then((res) => {
          const obj = {};
          obj.time = 86400000; // 过期时间(一天)
          obj.date = new Date().getTime();
          obj.dateTime = dateUtil.formatDate2(new Date());
          const objString = JSON.stringify(obj); // 由于 localStorage 只能保存字符串内容，所以这里要先把对象转换成 JSON 字符串
          window.localStorage.setItem("data", objString);

          if (localStorage.getItem('routerHistory')) {
            let path = localStorage.getItem('routerHistory');
            this.$router.replace({
              path: path,
            });
          } else {
            this.$router.replace({ path: "/menu" });
            Toast.success({ type: "success", message: "登录成功" });
          }
        })
        .catch((erro) => {
          Toast.success({ type: "error", message: "账号或密码错误" });
          this.refreshCode();
        });
      this.isloading = false;
    },
    refreshCode() {
      getCaptcha().then((res) => {
        this.loginForm.key = res.data.key;
        this.loginForm.image = res.data.image;
      });
    },
    onConfirm(value) {
      this.loginForm.tenantId = value.value;
      this.loginForm.tenantName = value.text;
      this.showPicker = false;
    },

    getTenantIdList() {
      getTenantIdListAPI(website.appCode).then((res) => {
        console.log(res, "租户下拉");
        let list = [];
        let arr = [];
        list = res.data.data.tenantList || [];
        if (list) {
          list.forEach((item) => {
            this.columns.push({ text: item.tenantName, value: item.tenantId });
          });
        }
        console.log(this.columns, "this.columns");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box{
  box-sizing: border-box;
}
.swipe {
  // padding: 10px 0;
  // margin: 30px auto;
}
.clean{
  height: 30px;
}
.login-code {
  position: absolute;
  // top: 135px;
  top: 89px;
  right: 0;
}
</style>>
